import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query ContactUs {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulContactUs {
      ...heroContactUs
      ...metaContactUs
      ...formSectionContactUs
      ...mapSectionContactUs
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.ContactUsQuery>> &
  ILayout = ({ data }) => {
  return <Template {...data} />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<
  PageProps<Queries.ContactUsQuery>
> = ({ data, location }) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      meta={data.contentfulContactUs?.meta}
      pageTitle="Contact Us"
      pathname={location.pathname}>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          address: {
            '@type': 'PostalAddress',
            addressLocality:
              data.contentfulContactUs?.mapReference?.officeAddressLocality,
            postalCode: data.contentfulContactUs?.mapReference?.officePostcode,
            streetAddress:
              data.contentfulContactUs?.mapReference?.officeStreetAddress,
          },
          name: 'Sputnik Digital',
          telephone: '0800 680 9777',
        })}
      </script>
    </Meta>
  );
};
